import apolloClient from "@/core/apollo";
import { IRemoteAuth, IRemoteCraftRole, IRemoteEmployeeAddress, IUserData } from "@/types";
import rolesQuery from "@/queries/roles.graphql";
import companyQuery from "@/queries/company.graphql";
import { get } from "lodash";
import userData from "../server/user.json";
import i18n from "@/core/i18n";
import { token, base64, errors } from "@/utilities";
import { isAdminLoggedIn } from "@/router";

enum EUserError {
	"auth" = "auth",
	"database" = "database",
	"api" = "api",
}

export const getUser = async (): Promise<IUserData | null> => {
	try {
		const { accessToken } = await token.getValidToken();

		const result = await Promise.all([
			(async (): Promise<IRemoteAuth | null> => {
				const response = await fetch("https://mintusprdidapp.azurewebsites.net/api/v1/auth", {
					method: "GET",
					headers: {
						accept: "*/*",
						Authorization: `Bearer ${accessToken}`,
					},
				});

				const data = await response.json();

				if (!response.ok) {
					if (
						data.summary[0] === "The database could not be reached." ||
						data.summary[0] === "An exception occurred."
					) {
						throw EUserError.database;
					}

					throw EUserError.auth;
				}

				return data as IRemoteAuth | null;
			})(),
			(async () => {
				const response = await fetch("https://mintusprdidapp.azurewebsites.net/api/v1/employee/photo", {
					method: "GET",
					headers: {
						accept: "*/*",
						Authorization: `Bearer ${accessToken}`,
					},
				});

				if (!response.ok) {
					return null;
				}

				const img = await response.blob();

				const base64String = await base64.blobToBase64(img);

				return base64String;
			})(),
			(async () => {
				const { data } = await apolloClient.query({
					query: rolesQuery,
					variables: {},
				});

				return data?.categories as IRemoteCraftRole[] | null;
			})(),
		]);

		const [user, img, roles] = result;

		if (!user?.isAuthenticated || !roles) {
			throw new Error("no user data found");
		}

		// @ts-ignore TODO: fix types
		return mapUser(user, img, roles);
	} catch (error) {
		const { t } = i18n.global;

		switch (error) {
			case EUserError.database:
				await errors.showErrorToast(t("local.error.database"));
				break;
			case EUserError.auth:
				await errors.showErrorToast(t("local.error.auth"));
				break;
			default:
				await errors.showErrorToast(t("local.error.api"));
				break;
		}

		return null;
	}
};

export const getAdminUser = async (): Promise<IUserData | null> => {
	try {
		const [user, roles] = await Promise.all([
			(async (): Promise<IRemoteAuth | null> => {
				return userData as IRemoteAuth | null;
			})(),
			(async () => {
				const { data } = await apolloClient.query({
					query: rolesQuery,
					variables: {},
				});

				return data?.categories as IRemoteCraftRole[] | null;
			})(),
		]);

		if (!user || !roles) {
			throw new Error("no user data found");
		}

		// @ts-ignore TODO: fix types
		return mapUser(user, null, roles);
	} catch {
		return null;
	}
};

const concatenateAddress = (address: IRemoteEmployeeAddress | undefined) => {
	if (!address) {
		return undefined;
	}

	const { t } = i18n.global;
	const { street, nr, box, postalCode, city } = address;

	return `${street ? `${street} ` : ""}${nr ? `${nr} ` : ""}<br>
	${box ? `${t("profile.address.bus")} ${box}<br>` : ""}
	${postalCode ? `${postalCode} ` : ""}${city ? `${city} ` : ""}`;
};

const mapUser = async (user: IRemoteAuth, img: string | null, roles: IRemoteCraftRole[]) => {
	const { data: company } = await apolloClient.query({
		query: companyQuery,
		variables: { title: user.employeeInfo.organization ?? "_" },
	});

	const { employeeInfo, roles: employeeRoles } = user;
	const { address, domicily } = employeeInfo;

	const info = {
		...employeeInfo,
		file: {
			name: employeeInfo.fileManagerName,
			email: employeeInfo.fileManagerEmail,
			phone: employeeInfo.fileManagerPhone,
		},
		companyLogo: get(company, "category.logo[0]", null),
		phone: get(employeeInfo, "phones[0].number", null),
		birthDate: employeeInfo.birthDate ? new Date(employeeInfo.birthDate).toLocaleDateString("en-GB") : "",
		address: concatenateAddress(address),
		domicily: concatenateAddress(domicily),
		img: img,
	};

	const roleIds = roles.filter((role) => employeeRoles.includes(role.title)).map((role) => role.id);

	// @ts-ignore TODO: fix types
	return { ...info, roles: roleIds };
};

export const updateProfilePicture = async (image: string) => {
	if (isAdminLoggedIn) {
		return;
	}

	const { t } = i18n.global;

	const { accessToken } = await token.getValidToken();

	try {
		const response = await fetch("https://mintusprdidapp.azurewebsites.net/api/v1/employee/photo", {
			method: "PUT",
			headers: {
				accept: "*/*",
				Authorization: `Bearer ${accessToken}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(image),
		});

		if (!response.ok) {
			switch (response.status) {
				case 413:
					errors.showErrorToast(t("local.error.img.too-large"));
					throw new Error(t("local.error.img.too-large"));
				case 415:
					errors.showErrorToast(t("local.error.img.too-large"));
					throw new Error(t("local.error.img.too-large"));
				default:
					errors.showErrorToast(t("local.error.img.general"));
					throw new Error(t("local.error.img.general"));
			}
		}
	} catch (error: any) {
		errors.showErrorToast(t("local.error.img.general"));

		throw new Error(error);
	}
};
