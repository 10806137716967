import apolloClient from "@/core/apollo";
import translationsQuery from "@/queries/translations.graphql";
import i18n from "@/core/i18n";
import { IRemoteTranslations, ITranslations } from "@/types/translations";
import { showErrorToast } from "@/utilities/error";

interface pageEntry {
	description: string;
	section: string;
	title: string;
}

export const getTranslations = async (): Promise<ITranslations | null> => {
	try {
		const { data } = await apolloClient.query({
			query: translationsQuery,
			variables: {},
		});

		if (!data) {
			return null;
		}

		const { messages } = i18n.global;
		const { staticMessages } = data;

		if (!messages || !staticMessages) {
			return null;
		}

		const mappedTranslations: ITranslations = {};

		staticMessages.forEach((message: IRemoteTranslations) => {
			mappedTranslations[message.key] = message.message;
		});

		// Add keys and messages from CMS pages data
		data.entries.forEach((entry: pageEntry) => {
			const { section, title, description } = entry;

			const titleKey = `${section}.title`;
			const descriptionKey = `${section}.description`;

			mappedTranslations[titleKey] = title;
			mappedTranslations[descriptionKey] = description;
		});

		// @ts-expect-error: i18n typings do not match with reality
		messages.value["nl"] = Object.assign(messages.value["nl"], mappedTranslations);

		// @ts-expect-error: i18n typings do not match with reality
		return messages.value["nl"];
	} catch {
		const { t } = i18n.global;

		await showErrorToast(t("local.error.api"));

		return null;
	}
};
